import "./Editor.scss";

import React from "react";
import { Editor as TinyMCEditor } from "@tinymce/tinymce-react";

class Editor extends React.Component<{name?: string, initialValue?: string, overrideInit?: any}, {currentValue: string}> {
  constructor(props: any) {
    super(props);
    this.state = {currentValue: this.props.initialValue || ""};
  }

 
  render() {
    return <div>
      <input type="hidden" name={this.props.name} value={this.state.currentValue} readOnly/>
      <TinyMCEditor
        onEditorChange={(v) => this.setState({currentValue: v})}
        tinymceScriptSrc={"/tinymce/tinymce.min.js"} 
        initialValue={this.props.initialValue}
        init={{
          height: 500,
          plugins:
            "code fullscreen",
          toolbar:
            " forecolor backcolor |  fontsize  | code | bold italic underline | link | alignleft aligncenter alignright alignjustify | code",
          content_style:
            "body{    --tw-bg-opacity: 1;  background: rgb(75 85 99 / var(--tw-bg-opacity)) }",
          advcode_inline: false,
          content_css: "dark",
          skin: "oxide-dark",
          menubar: false,
          ...(this.props.overrideInit || {})
        }}
      />
    </div>
  }
}

export default Editor;
