import "./Alerts.scss";

import React from "react";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { alerts } from "../../utils/alerts";

class Alerts extends React.Component<{}, { alerts: any }> {
  constructor(props: any) {
    super(props);
    this.state = { alerts: [] };
    this.addAlert = this.addAlert.bind(this);
    this.removeAlert = this.removeAlert.bind(this);
    alerts.setOnNewAlertHandler((a: any) => {
      this.addAlert(a)
    })
  }

  addAlert(alert: any) {
    this.setState((prevState) => ({
      alerts: [...prevState.alerts, alert],
    }));
  }

  removeAlert(alert: any) {
    this.setState((prevState) => ({
      alerts: [...prevState.alerts.filter((it: any) => it !== alert)],
    }));
  }

  render() {
    return (
      <div className="fixed bottom-0 left-0 right-0 alert-holder">
        <div className="mx-auto max-w-7xl  py-6 sm:px-6 lg:px-8">
          <div className="px-4 py-6 sm:px-0">
            {this.state.alerts.map((alert: any, idx: any) => {
              const classN = ({
                "error": " text-red-400 border-red-800",
                "success": " text-green-400 border-green-800",
                "infos": " text-blue-400 border-blue-800"
              } as any)[alert.type || "infos"]
              return (
                <div
                  key={idx}
                  onClick={() => this.removeAlert(alert)}
                  id="alert-additional-content-2"
                  className={ "shadow-md alert-item p-4 mb-4 border rounded-lg " + classN}
                  style={{background: "#111827cc"}}
                  role="alert"
                >
                  <div className="flex items-center">
                    <ExclamationCircleIcon className="block h-6 w-6" />
                    &nbsp;&nbsp;
                    <span className="sr-only">Info</span>
                    <h3 className="text-lg font-medium">{alert.title}</h3>
                  </div>
                  {
                    (!!alert.summary) 
                    ? <div className="mt-2 text-sm">{alert.summary}</div>
                    : []
                  }
                  
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default Alerts;
