import "./Appointments.scss";

import { PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { t } from "i18next";
import React from "react";
import ax from "../../../http/axios";
import { getUser } from "../../../utils/auth";
import Modal from "../../elements/modal/Modal";
import Editor from "../../elements/editor/Editor";
import { alerts } from "../../../utils/alerts";

class Appointments extends React.Component<
  {},
  { userData: any; appointments: any; addModalOpened: boolean }
> {
  constructor(props: any) {
    super(props);
    this.state = { userData: null, appointments: null, addModalOpened: false };
    this.loadData();
    this.openAddModal = this.openAddModal.bind(this);
    this.closeAddModal = this.closeAddModal.bind(this);
    this.onAddAppointment = this.onAddAppointment.bind(this);
  }

  async loadData() {
    const userData = await getUser();
    const appointments = (await ax.get("/api/appointments?limit=20")).data;
    this.setState({ userData, appointments });
  }

  async openAddModal() {
    this.setState({ addModalOpened: true });
  }

  async closeAddModal() {
    this.setState({ addModalOpened: false });
  }

  private async deleteAppointment(id: any) {
    const r = await ax.delete("/api/appointment/" + id)
    if((r as any).status == 200){
      await this.loadData()
      alerts.addAlert({type: "success", title: t("appointments.delete.alert.success")})
    }
  }

  private async onAddAppointment(e: any) {
    e.preventDefault();
    const form = Object.fromEntries(
      new FormData(e.target).entries()
    ) as any;
    const r = await ax.post("/api/appointment", form)
    if((r as any).status == 200){
      await this.loadData()
      alerts.addAlert({type: "success", title: t("appointments.create_modal.alert.success")})
      await this.closeAddModal()
    }
  
    
  }

  private getNextAppointmentDate() {
    const now = new Date();
    return (
      now.getFullYear() +
      1 +
      "-" +
      (now.getUTCMonth() + 1 + "").padStart(2, "0") +
      "-" +
      (now.getUTCDate() + "").padStart(2, "0") +
      " 15:00"
    );
  }

  render() {
    if (!this.state.userData) {
      return [];
    }
    return (
      <div>
        <Modal visible={this.state.addModalOpened}>
          <div className="px-6 py-6 lg:px-8">
            <button
              type="button"
              className="absolute top-3 right-2.5  bg-transparent  rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center hover:bg-gray-600 hover:text-white"
              onClick={this.closeAddModal}
            >
              <XMarkIcon className="w-6 h-6" />
            </button>
            <h3 className="mb-4 text-xl font-medium ">
              {t("appointments.create_modal.title")}
            </h3>
            <form className="space-y-6" action="#" onSubmit={this.onAddAppointment}>
              <div>
                <label className="block mb-2 text-sm font-medium ">
                  {t("appointments.create_modal.form.reason")}
                </label>
                <Editor name="reason" initialValue="<span style='color:red'> Blood </span> Analysis" />
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium ">
                  {t("appointments.create_modal.form.date")}
                </label>
                <input
                  type="text"
                  name="date"
                  id="date"
                  placeholder={this.getNextAppointmentDate()}
                  className=" border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 bg-gray-600 border-gray-500 placeholder-gray-400 text-white"
                  required
                />
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium  text-white">
                  {t("appointments.create_modal.form.identity_number")}
                </label>
                <input
                  type="text"
                  name="identity_number"
                  id="identity_number"
                  placeholder="1234567890"
                  className=" border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 bg-gray-600 border-gray-500 placeholder-gray-400 text-white"
                  required
                />
              </div>

              <button
                type="submit"
                className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-blue-600 hover:bg-blue-700 focus:ring-blue-800"
              >
                {t("appointments.create_modal.form.submit")}
              </button>
            </form>
          </div>
        </Modal>

        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-400">
            <caption className="p-5 text-lg font-semibold text-left text-white bg-gray-800">
              <span
                dangerouslySetInnerHTML={{
                  __html: t("appointments.list-title", {
                    interpolation: { escapeValue: false },
                  }),
                }}
              ></span>
              <p className="mt-1 text-sm font-normal text-gray-400">
                {t("appointments.list-summary")}
              </p>

              <div className="absolute right-5 top-7">
                <button
                  type="button"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center mr-2 bg-blue-600 hover:bg-blue-700 focus:ring-blue-800"
                  onClick={this.openAddModal}
                >
                  <PlusIcon className="w-4 h-4" />
                  <span className="sr-only">Icon description</span>
                </button>
              </div>
            </caption>
            <thead className="text-xs  uppercase bg-gray-700 text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  {t("appointments.list.headers.reason")}
                </th>
                <th scope="col" className="px-6 py-3">
                  {t("appointments.list.headers.lastname")}
                </th>

                <th scope="col" className="px-6 py-3">
                  {t("appointments.list.headers.firstname")}
                </th>

                <th scope="col" className="px-6 py-3">
                  {t("appointments.list.headers.date")}
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {this.state.appointments.map((appointment: any, idx: number) => {
                return (
                  <tr
                    key={idx}
                    className="border-b bg-gray-800 border-gray-700"
                  >
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium  whitespace-nowrap text-white"
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: appointment.reason,
                        }}
                      ></span>
                      {appointment.vip ? (
                        <span className="bg-yellow-100 ml-5 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded">
                          {t("appointments.list.values.vip")}
                        </span>
                      ) : (
                        []
                      )}
                    </th>
                    <td className="px-6 py-4">{appointment.lastname}</td>
                    <td className="px-6 py-4">{appointment.firstname}</td>
                    <td className="px-6 py-4">{appointment.date_and_time}</td>
                    <td className="px-6 py-4 text-right">
                      <span

                        onClick={() => this.deleteAppointment(appointment._id)}
                        className="font-medium text-blue-600 text-blue-500 hover:underline"
                      >
                        {t("appointments.list.delete")}
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default Appointments;
