import './Navbar.scss';
 
 import React from 'react';
import DefaultMenu from './default-menu/DefaultMenu';
import DefaultRightMenu from './default-right-menu/DefaultRightMenu';
import logo from "../../../images/logo.png"
import { NavLink } from 'react-router-dom';

class Navbar extends React.Component<{}, { }> {
 


  render() {
    return (

        <nav className="fixed top-0 z-50 w-full border-b border-gray-200 bg-gray-800 border-gray-700">
        <div className="px-3 py-3 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div className="flex-shrink-0">

                <NavLink to="/" className="logo">
                <img src={logo} alt="Hospital Whisperer" />
                </NavLink>
              </div>
              <DefaultMenu/>
            </div>
            <DefaultRightMenu />
          </div>
        </div>
      </nav>

    );
  }
}

export default Navbar;


