import "./Login.scss";
import logo from "../../../images/logo.png";
import React from "react";
import { t } from "i18next";
import { performLogin } from "../../../utils/auth";
import { NavLink } from "react-router-dom";

class Login extends React.Component<{}, {}> {
  async onSubmitHandler(e: any) {
    e.preventDefault();
    const { email, password } = Object.fromEntries(
      new FormData(e.target).entries()
    ) as any;
    if (await performLogin(email, password)) {
      (window as any).location = "/";
    }
  }

  render() {
    return (
      <div>
        <section className="bg-gray-900">
          <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
            <span className="flex items-center mb-6 text-2xl font-semibold text-white">
              <img className="h-16 mr-2" src={logo} alt="logo" />
            </span>
            <div className="w-full rounded-lg shadow border md:mt-0 sm:max-w-md xl:p-0 bg-gray-800 border-gray-700">
              <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                <h1 className="text-xl font-bold leading-tight tracking-tight md:text-2xl text-white">
                  {t("login.title")}
                </h1>
                <form
                  className="space-y-4 md:space-y-6"
                  onSubmit={this.onSubmitHandler}
                >
                  <div>
                    <label className="block mb-2 text-sm font-medium text-white">
                      {t("login.fields.email.label")}
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder={t("login.fields.email.placeholder")}
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                  <div>
                    <label className="block mb-2 text-sm font-medium text-white">
                      {t("login.fields.password.label")}
                    </label>
                    <input
                      type="password"
                      name="password"
                      id="password"
                      placeholder={t("login.fields.password.placeholder")}
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                  <button
                    type="submit"
                    className="w-full text-white bg-blue-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-primary-600 hover:bg-primary-700 focus:ring-primary-800"
                  >
                    {t("login.button")}
                  </button>
                  <p className="text-sm font-light text-gray-400">
                    {t("login.no-account-yet")}
                    &nbsp;
                    <NavLink
                      to="/register"
                      className="font-medium hover:underline text-primary-500"
                    >
                      {t("login.register")}
                    </NavLink>
                  </p>
                </form>
              </div>
            </div>
            <div className="text-gray-600 py-6 text-sm">
          {t('technical.commit_hash')}
        </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Login;
