import "./Profile.scss";

import React from "react";
import { getUser } from "../../../utils/auth";
import { withParams } from "../../../helpers";
import ax from "../../../http/axios";
import { t } from "i18next";
import { alerts } from "../../../utils/alerts";

class Profile extends React.Component<
  {},
  { userData: any; username?: string; password?: string }
> {
  constructor(props: any) {
    super(props);

    this.state = { userData: null };
    this.loadUserData();
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
  }

  async onSubmitHandler(e: any) {
    e.preventDefault();
    const f = Object.fromEntries(
      Object.entries(this.state)
        .filter((it) => ["username", "password"].indexOf(it[0]) !== -1)
        .filter((it) => it[1].length > 0)
    );
    const profileId = (this.props as any).params.id;
    const r = await ax.patch("/api/user/" + profileId, f);
    if (r.status === 200) {
      alerts.addAlert({
        type: "success",
        title: "Successfully updated profile",
      });
    }
  }

  async loadUserData() {
    const profileId = (this.props as any).params.id;
    if (profileId == null) {
      this.setState({ userData: await getUser() });
    } else {
      const userData = (await ax.get("/api/user/" + profileId)).data;
      this.setState({ userData, username: userData.username, password: "" });
    }
  }

  render() {
    if (!this.state.userData) {
      return [];
    }
    const profileId = (this.props as any).params.id;
    if (profileId == null) {
      (window as any).location = "/profile/" + this.state.userData._id
      return []
    }
    return (
      <div>
        <h1 className="text-xl text-center m-8 font-bold">
          {t("profile.edit.title")}
        </h1>
        <div className="block">
          <div className=" p-5 rounded bg-gray-50 bg-gray-800 max-w-3xl mx-auto align-center relative">
            <form
              className="space-y-4 md:space-y-6"
              onSubmit={this.onSubmitHandler}
            >
              <div>
                <label className="block mb-2 text-sm font-medium text-white">
                  {t("profile.edit.fields.username.label")}
                </label>
                <input
                  type="username"
                  name="username"
                  id="username"
                  value={this.state.username}
                  onChange={(e) => this.setState({ username: e.target.value })}
                  placeholder={t("profile.edit.fields.username.placeholder")}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-white">
                  {t("profile.edit.fields.password.label")}
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  value={this.state.password}
                  onChange={(e) => this.setState({ password: e.target.value })}
                  placeholder={t("profile.edit.fields.password.placeholder")}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <button
                type="submit"
                className="w-full text-white bg-blue-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-primary-600 hover:bg-primary-700 focus:ring-primary-800"
              >
                {t("profile.edit.button")}
              </button>
            </form>
          </div>
        </div>
 
      </div>
    );
  }
}

export default withParams(Profile);
