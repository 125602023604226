import "./Modal.scss";

import React from "react";


class Modal extends React.Component<{ children: any; visible?: boolean }, {}> {
  render() {
    return (
      <div>
        <div
          id="defaultModal"
          tabIndex={-1}
          aria-hidden="true"
          className={
            (this.props.visible ? "flex" : "hidden") +
            " fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full justify-center items-center flex"
          }
          role="dialog"
        >
          <div className="backdrop fixed top-0 left-0 right-0 bottom-0" />
          <div className="relative w-full max-w-2xl max-h-full">
            <div className="relative rounded-lg shadow bg-gray-700 text-white">
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
