import Navbar from "../../components/elements/navbar/Navbar";
import Sidebar from "../../components/elements/sidebar/Sidebar";
import "./Default.scss";

import React from "react";

class Default extends React.Component<{element: JSX.Element}, {}> {

  render() {

    return (
      <div>
        <Navbar></Navbar>
        <Sidebar></Sidebar>
        <div className="p-4 sm:ml-64 mt-14 text-white">
          {this.props.element}
        </div>
      </div>
    );
  }
}

export default Default;
