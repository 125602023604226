import Appointments from "./components/pages/appointments/Appointments"
import Dashboard from "./components/pages/dashboard/Dashboard"
import Home from "./components/pages/home/Home"
import Login from "./components/pages/login/Login"
import Logout from "./components/pages/logout/Logout"
import Patient from "./components/pages/patient/Patient"
import Patients from "./components/pages/patients/Patients"
import Profile from "./components/pages/profile/Profile"
import Register from "./components/pages/register/Register"
import SecretNotes from "./components/pages/secretNotes/SecretNotes"
import Default from "./skeletons/default/Default"
 
const routes = [
    {  path: "/login", component: (<Login/>) },
    {  path: "/register", component: (<Register/>) },
    {  path: "/logout", component: (<Logout/>) },
    
    {  path: "/", component: <Default element={<Home/>}/> },
    {  path: "/dashboard",  component: <Default element={<Dashboard/>} /> },
    {  path: "/appointments",  component: <Default element={<Appointments/>}/> },
    {  path: "/patients", component: <Default element={<Patients/>}/> },
    {  path: "/patient/:id", component: <Default element={<Patient/>}/> },
    {  path: "/profile", component: <Default element={<Profile/>}/> },
    {  path: "/profile/:id", component: <Default element={<Profile/>}/> },
    {  path: "/secretNotes", component: <Default element={<SecretNotes/>}/> },

]

export default routes
