import "./Dashboard.scss";

import React from "react";
import { getUser } from "../../../utils/auth";
import { t } from "i18next";
import ax from "../../../http/axios";

class Dashboard extends React.Component<{}, { userData: any, statistics: any }> {
  constructor(props: any) {
    super(props);
    this.state = { userData: null, statistics: null };
    this.loadData();
  }

  async loadData() {
    const stats = (await ax.get('/api/stats')).data
    console.log(stats)
    this.setState({ userData: await getUser(), statistics: stats });
  }

  render() {
    if (!this.state.statistics) {
      return [];
    }
    console.log(this.state)

    const statistics = [
      {
        title: t("dashboard.statistics.users.title"),
        subtitle: t("dashboard.statistics.users.subtitle"),
        value: this.state.statistics.users,
      },
      {
        title: t("dashboard.statistics.patients.title"),
        subtitle: t("dashboard.statistics.patients.subtitle"),
        value:  this.state.statistics.patients,
      },
      {
        title: t("dashboard.statistics.appointments.title"),
        subtitle: t("dashboard.statistics.appointments.subtitle"),
        value:  this.state.statistics.appointments,
      },
      {
        title: t("dashboard.statistics.notes.title"),
        subtitle: t("dashboard.statistics.notes.subtitle"),
        value:  this.state.statistics.notes,
      },
    ];

    return (
      <div className="p-10">
        <h1
          className="font-semibold text-gray-900 text-center text-white mb-10"
          style={{ fontSize: "3em" }}
        >
          {t('dashboard.title')}
        </h1>
        <div className="grid mb-8 border rounded-lg shadow-sm border-gray-700 md:mb-12 md:grid-cols-2">
          {
            statistics.map((stat) => {
              return (
                <figure className="flex flex-col items-center justify-center p-8 text-center border-b  rounded-t-lg md:rounded-t-none md:rounded-tl-lg md:border-r bg-gray-800 border-gray-700">
                <blockquote className="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8 text-gray-400">
                  <h2
                    className="font-semibold text-gray-900 text-white"
                    style={{ fontSize: "3em" }}
                  >
                    {stat.value}K+
                  </h2>
                  <h3 className="mt-2 text-lg font-semibold text-gray-400 ">
                    {stat.title}
                  </h3>
                  <p className="mt-2 text-sm text-gray-600">
                  {stat.subtitle}
                  </p>
                </blockquote>
              </figure>
              )
            })
          }
 
        </div>
      </div>
    );
  }
}

export default Dashboard;
