import "./SecretNotes.scss";

import React from "react";
import { getUser } from "../../../utils/auth";
import ax from "../../../http/axios";
import { Navigate } from "react-router-dom";
import { LockClosedIcon, LockOpenIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { t } from "i18next";
import Modal from "../../elements/modal/Modal";
import { alerts } from "../../../utils/alerts";
import { decode } from "../../../utils/secrets";

class SecretNotes extends React.Component<
  {},
  {
    userData: any;
    notes: any;
    goHome: boolean;
    modalOpened: boolean;
    currentNote: null;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      userData: null,
      notes: null,
      goHome: false,
      modalOpened: false,
      currentNote: null,
    };
    this.loadUserData();
    this.onUnlockNote = this.onUnlockNote.bind(this);
    this.tryUnlock = this.tryUnlock.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  async loadUserData() {
    const notes = await ax.get("/api/secrets");
    if (notes.status !== 200) {
      this.setState({ goHome: true });
    }
    this.setState({ userData: await getUser(), notes: notes.data });
  }

  async openModal() {
    this.setState({ modalOpened: true });
  }

  async closeModal() {
    this.setState({ modalOpened: false });
  }

  async tryUnlock(note: any) {
    this.setState({ currentNote: note });
    this.openModal();
  }

  async tryUnlockWithPass(note: any, pass: any) {
    const r = decode(note.note, pass) as any;
    if (r.error) {
      alerts.addAlert({
        type: "error",
        title: t("secret_notes.unlock_modal.error"),
      });
    } else {
      alerts.addAlert({
        type: "success",
        title: t("secret_notes.unlock_modal.success"),
      });
      note.note = r;
      note.unlocked = true;
      this.closeModal();
    }
  }

  private async onUnlockNote(e: any) {
    e.preventDefault();
    const form = Object.fromEntries(new FormData(e.target).entries()) as any;
    this.tryUnlockWithPass(this.state.currentNote, form.password);
  }

  render() {
    if (this.state.goHome) {
      return <Navigate to="/" />;
    }
    if (!this.state.notes) {
      return [];
    }
    return (
      <div>
        <Modal visible={this.state.modalOpened}>
          <div className="px-6 py-6 lg:px-8">
            <button
              type="button"
              className="absolute top-3 right-2.5  bg-transparent  rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center hover:bg-gray-600 hover:text-white"
              onClick={this.closeModal}
            >
              <XMarkIcon className="w-6 h-6" />
            </button>
            <h3 className="mb-4 text-xl font-medium ">
              {t("secret_notes.unlock_modal.title", {
                note: (this.state.currentNote || ({} as any)).title,
              })}
            </h3>
            <form className="space-y-6" action="#" onSubmit={this.onUnlockNote}>
              <div>
                <label className="block mb-2 text-sm font-medium ">
                  {t("secret_notes.unlock_modal.form.fields.password.label")}
                </label>
                <input
                  type="text"
                  name="password"
                  id="password"
                  className=" border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 bg-gray-600 border-gray-500 placeholder-gray-400 text-white"
                />
              </div>
              <button
                type="submit"
                className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-blue-600 hover:bg-blue-700 focus:ring-blue-800"
              >
                {t("secret_notes.unlock_modal.form.submit")}
              </button>
            </form>
          </div>
        </Modal>
        <h1 className="text-3xl text-center m-8 font-bold">
          {t("secret_notes.title")}
        </h1>
        <p className="text-center px-10 mb-10 text-sm text-gray-500">
        {t("secret_notes.subtitle")}
        </p>
        {this.state.notes.map((it: any) => {
          if (it.unlocked) {
            return (
              <div>
                <div className=" text-green-500 p-5 my-3 rounded border-2 border-green-800 relative shadow" style={{background: "rgba(0,0,0,0.2)"}}>
                  <div className="text-xl font-bold mb-2">
                    {it.title}
                    <div className="absolute right-5 top-3 font-light whitespace-nowrap">
                    {t("secret_notes.note_unlocked")}
                    <LockOpenIcon className="ml-2 w-5 h-6 inline align-top" />

                    </div>
                  </div>

                  <p
                    className="break-all font-mono text-sm text-green-700 overflow-hidden relative whitespace-pre-line"
                  >
                    {it.note}
                  </p>
                </div>
              </div>
            );
          }
          return (
            <div>
              <div className=" p-5 my-3 rounded bg-gray-50 bg-gray-800 relative">
                <div className="text-gray-200 text-xl font-bold mb-2">
                  {it.title}
                  <div className="absolute right-5 top-3">
                    <button
                      type="button"
                      className="text-gray-500 border-solid border-2 border-gray-600 hover:border-green-500 hover:text-green-400 hover:outline-none font-medium rounded-lg text-sm p-2 text-center inline-flex items-center mr-2 "
                      onClick={() => this.tryUnlock(it)}
                    >
                      <LockClosedIcon className="w-4 h-4" />
                      <span className="sr-only">Icon description</span>
                    </button>
                  </div>
                </div>

                <p
                  className="break-all font-mono text-sm text-gray-500 overflow-hidden relative"
                  style={{ maxHeight: "6em" }}
                >
                  {it.note}
                  <div className="absolute left-0 bottom-0 right-0 top-0 bg-gradient-to-t from-gray-800"></div>
                </p>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default SecretNotes;
