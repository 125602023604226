const fr = {
  technical: {
    commit_hash: ("<GITCOMMITHASH>").substring(0,8).toUpperCase()
  },
  lang: {
    "current": "Français",
    "fr" : "Français",
    "en": "Anglais"
  },
  menu : {
    "home": "Accueil",
    "profile": "Profil",
    "settings": "Paramètres",
    "logout":"Déconnexion"
  }, 
  sidebar: {
    "dashboard": "Tableau de bord",
    "places": "Lieux",
    "appointments": "Rendez-vous",
    "patients": "Patients",
    "secret_notes": "Notes secrètes",
 },
 appointments: {
  "list-title": "Rendez-vous", 
  "list-summary": "Veuillez noter que vous ne cherchez qu'à voir les événements à venir.",
  "create_modal":{
    "title": "Créer un nouveau rendez-vous", 
    "form": {
      "reason": "Raison du rendez-vous",
      "date": "Date du rendez-vous",
      "identity_number": "Numéro d'identité personnel",
      "submit": "Créer le rendez-vous"
    }, 
    "alert": {
      "success": "Le rendez-vous a été créé avec succès"
    }
  },
  "delete": {
    "alert": {
      "success" : "Le rendez-vous a été supprimé avec succès"
    }
  },
  "list": {
    "headers": {
      "reason": "Raison",
      "lastname": "Nom",
      "firstname": "Prénom", 
      "date": "Date",
      "tags": "Étiquettes",
    },
    "values":{
      "vip": "V.I.P."
    },
    "delete": "Supprimer"
  }
 },
 patients: {
  "list-title": "Recherche de patients : {{search}} ", 
  "list-summary": "En raison de limites techniques, nous ne pouvons afficher plus de 10 patients à la fois.",
  "search": {
    "placeholder": "Recherche de patients ...",
    "button" : "Recherche"
  },
  "list": {
    "view": "Voir",
    "headers": {
      "firstname": "Prénom", 
      "lastname": "Nom",
      "gender": "Genre",
      "identity_number": "NIP",
      "date_of_birth": "Date de naissance",
      "address": "Adresse",
      "phone_number":"Numéro de téléphone"
    }
  }
 },
 patient: {
  "age": "{{age}} ans ({{dob}})",
  "vip": "V.I.P.",
  "past-appointments": "Rendez-vous passés",
  "next-appointments": "Prochains rendez-vous",
  "notes": "Notes",
  "no-past-appointments": "Pas de rendez-vous passé",
  "no-next-appointments": "Pas de prochains rendez-vous",
  "no-notes": "Pas de notes"
 },
  login: {
    "title": "Connectez-vous à votre compte",
    "fields": {
      "email": {
        "label": "Email", 
        "placeholder": "jean.michel@hospital-whisperer.com"
      }, 
      "password": {
        "label": "Mot de passe", 
        "placeholder": "mot de passe"
      }
    }, 
    "button": "Se connecter",
    "no-account-yet": "Pas encore inscrit ?",
    "register": "S'inscrire"
  },
  "secret_notes": {
    "title": "Notes secrètes",
    "subtitle": "Bienvenue dans le royaume énigmatique des Notes Secrètes, où les vérités cachées, les codes mystérieux et les découvertes révolutionnaires prennent vie. Embarquez pour un voyage de secret et d'intrigue alors que vous explorez les réflexions confidentielles d'un scientifique estimé et leurs rencontres extraordinaires avec la vie éternelle. Plongez dans les pensées privées, les messages codés et les réflexions qui suscitent la réflexion qui peignent un tableau vivant d'un monde au bord de révélations scientifiques sans précédent. Démêlez les énigmes, contemplez les dilemmes éthiques et immergez-vous dans les récits captivants qui se trouvent dans ces pages. Entrez dans le monde de l'inconnu, où les murmures de l'éternité résonnent à travers la tapisserie du temps, vous laissant avec des questions qui remettent en question l'essence même de l'existence.",
    "note_unlocked": "Déverrouillé",
    "unlock_modal": {
      "title": "Essayer de déverrouiller la note : {{note}}", 
      "success": "Déverrouillage réussi",
      "error": "Échec du déverrouillage",
      "form": {
        "fields":{
          "password":{
            "label": "Mot de passe"
          }
        },
        "submit": "Essayer de déverrouiller"
      }
    }
  },
  "profile": {
    "edit": {
      "title" : "Modifier le profil",
      "fields": {
        "username": {
          "label": "Nom d'utilisateur", 
          "placeholder": "Le Docteur Célèbre"
        },
        "password": {
          "label": "Mot de passe (Laissez vide pour garder l'ancien)", 
          "placeholder": "Nouveau mot de passe"
        },
      },
      "button": "Mettre à jour"
    }
  },
  
    home: {
      title: "Bienvenue dans Hospital Whisperer",
      text: `
      Nous sommes ravis de vous présenter notre boîte à outils de soins de santé révolutionnaire conçue pour être votre compagnon ultime dans l'environnement hospitalier. Hospital Whisperer est là pour transformer votre expérience hospitalière quotidienne, la rendant plus efficace, organisée et fluide.
  
      Avec Hospital Whisperer, gérer les tâches et les informations des patients devient un jeu d'enfant. Notre système intelligent vous assure de rester organisé et concentré sur la prestation de soins exceptionnels à vos patients. De la planification du traitement à la surveillance, vous aurez tous les outils dont vous avez besoin pour fournir les meilleurs soins médicaux possibles.
      
      Dites adieu aux silos d'information ! Notre intégration intelligente avec les systèmes hospitaliers permet un échange de données sans effort, vous donnant un accès rapide aux dossiers de patients essentiels et favorisant une collaboration fluide avec votre équipe de soins de santé.
      
      Chez Hospital Whisperer, nous sommes animés par une passion pour l'excellence des soins de santé. Notre objectif est de vous donner accès à une technologie de pointe qui simplifie vos routines quotidiennes, afin que vous puissiez passer plus de temps à faire ce que vous aimez : prendre soin de vos patients.
      
      Votre dévouement envers les soins de santé nous inspire, et nous nous engageons à vous soutenir tout au long de votre parcours. Faites confiance à Hospital Whisperer pour améliorer votre pratique médicale, optimiser votre flux de travail et, en fin de compte, améliorer les résultats pour les patients.
      
      Merci d'avoir choisi Hospital Whisperer. Ensemble, révolutionnons la manière dont les soins de santé sont dispensés et créons un avenir plus radieux pour tous.
      
      Bienvenue à bord !          
    `,
    },
    dashboard: {
      title: "Tableau de bord",
      statistics: {
        users: {
          title: "Utilisateurs",
          subtitle:
            "Notre base d'utilisateurs a connu une croissance exponentielle, regroupant une communauté diversifiée de professionnels de la santé, de développeurs et d'enthousiastes de la sécurité. Ensemble, nous nous engageons à faire progresser la connaissance et la sensibilisation à la sécurité des logiciels hospitaliers.",
        },
        patients: {
          title: "Patients",
          subtitle:
            "Nous servons fièrement un nombre substantiel de patients, qui nous ont été confiés par des institutions médicales du monde entier. La protection de la vie privée et des données est au cœur de notre mission.",
        },
        appointments: {
          title: "Rendez-vous",
          subtitle:
            "Le volume de rendez-vous géré via notre plateforme reflète l'efficacité et la fiabilité de nos solutions logicielles hospitalières. Nous accordons la priorité à la sécurité de ces processus de planification pour optimiser les soins aux patients.",
        },
        notes: {
          title: "Notes",
          subtitle:
            "Notre vaste collection de notes au sein du système témoigne de la qualité des dossiers médicaux complets tenus par les prestataires de soins de santé. Des mesures de sécurité sont rigoureusement mises en place pour protéger ces informations critiques.",
        },
      },
    },
    register: {
      title: "S'inscrire",
      fields: {
        username: {
          label: "Nom d'utilisateur",
          placeholder: "Le Docteur Célèbre",
        },
        email: {
          label: "Email",
          placeholder: "jean.michel@hospital-whisperer.com",
        },
        password: {
          label: "Mot de passe",
          placeholder: "mot de passe",
        },
        captcha: {
          label: "Captcha",
          placeholder: "120",
        },
      },
      button: "S'inscrire",
      "already-has-account": "Vous avez déjà un compte ?",
      login: "Se connecter",
    },
  };
  

export default fr;
