import {  UserIcon } from '@heroicons/react/24/outline'

export const MainMenu = [
    {label: "menu.home", path: "/"},
]

export const SubMenu = [
    {
        label: "Compte", 
        icon: <UserIcon/>, 
        links: [
            {label: "menu.profile", path: "/profile"},
            {label: "menu.logout", path: "/logout"}        
        ]
    }
]