class Alerts {

    private onNewAlertHandler = null;
    setOnNewAlertHandler(handler: any){
        this.onNewAlertHandler = handler
    }

    public addAlert(alert: any){
        if(this.onNewAlertHandler != null) {
            (this.onNewAlertHandler as any)(alert)
        }
    }


}

export const alerts = new Alerts()