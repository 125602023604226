const en = {
  technical: {
    commit_hash: ("<GITCOMMITHASH>").substring(0,8).toUpperCase()
  },
  lang: {
    "current": "English",
    "fr" : "French",
    "en": "English"
  },
  menu : {
    "home": "Home",
    "profile": "Profile",
    "settings": "Settings",
    "logout":"Logout"
  }, 
  sidebar: {
    "dashboard": "Dashboard",
    "places": "Places",
    "appointments": "Appointments",
    "patients": "Patients",
    "secret_notes": "Secret notes",
 },
 appointments: {
  "list-title": "Appointments", 
  "list-summary": "Please note that you are only looking to upcoming events.",
  "create_modal":{
    "title": "Create a new appointment", 
    "form": {
      "reason": "Reason of the appointment",
      "date": "Date of the appointment",
      "identity_number": "Personal identity number",
      "submit": "Create appointment"
    }, 
    "alert": {
      "success": "Successfully created the appointment"
    }
  },
  "delete": {
    "alert": {
      "success" : "Successfully removed the appointment"
    }
  },
  "list": {
    "headers": {
      "reason": "Reason",
      "lastname": "Lastname",
      "firstname": "Firstname", 
      "date": "Date",
      "tags": "Tags",
    },
    "values":{
      "vip": "V.I.P."
    },
    "delete": "Delete"
  }
 },
 patients: {
  "list-title": "Searching for patients : {{search}} ", 
  "list-summary": "Due to technical limits, we cannot show more than 10 patients at a time.",
  "search": {
    "placeholder": "Search for patients ...",
    "button" : "Search"
  },
  "list": {
    "view": "View",
    "headers": {
      "firstname": "Firstname", 
      "lastname": "Lastname",
      "gender": "Gender",
      "identity_number": "PIN",
      "date_of_birth": "Date of Birth",
      "address": "Address",
      "phone_number":"Phone number"
    }
  }
 },
 patient: {
  "age": "{{age}} years old ({{dob}})",
  "vip": "V.I.P.",
  "past-appointments": "Past Appointments",
  "next-appointments": "Upcoming Appointments",
  "notes": "Notes",
  "no-past-appointments": "No past appointments",
  "no-next-appointments": "No upcoming appointments",
  "no-notes": "No notes"
 },
  login: {
    "title": "Sign-in to your account",
    "fields": {
      "email": {
        "label": "Email", 
        "placeholder": "jean.michel@hospital-whisperer.com"
      }, 
      "password": {
        "label": "Password", 
        "placeholder": "password"
      }
    }, 
    "button": "Sign-in",
    "no-account-yet": "Don't yet registered ?",
    "register": "Sign-up"
  },
  "secret_notes": {
    "title": "Secret notes",
    "subtitle": "Welcome to the enigmatic realm of Secret Notes, where hidden truths, mysterious codes, and groundbreaking discoveries come to life. Embark on a journey of secrecy and intrigue as you explore the confidential musings of an esteemed scientist and their extraordinary encounters with eternal life. Delve into the private thoughts, encoded messages, and thought-provoking reflections that paint a vivid picture of a world on the brink of unparalleled scientific revelations. Unravel the enigmas, contemplate ethical dilemmas, and immerse yourself in the captivating narratives that lay within these pages. Step into the world of the unknown, where whispers of eternity echo through the tapestry of time, leaving you with questions that challenge the very essence of existence.",
    "note_unlocked": "Unlocked",
    "unlock_modal": {
      "title": "Trying to unlock note : {{note}}", 
      "success": "Successfully unlocked",
      "error": "Failed to unlock",
      "form": {
        "fields":{
          "password":{
            "label": "Password"
          }
        },
        "submit": "Try to unlock"
      }
    }
  },
  "profile": {
    "edit": {
      "title" : "Edit profile",
      "fields": {
        "username": {
          "label": "Username", 
          "placeholder": "The Famous Doctor"
        },
        "password": {
          "label": "Password (Leave empty to keep the old one)", 
          "placeholder": "New password"
        },
      },
      "button": "Update"
    }
  },
  "home": {
    "title": "Welcome to Hospital Whisperer", 
    "text": `
    We are thrilled to introduce you to our revolutionary healthcare toolkit designed to be your ultimate companion in the hospital environment. The Hospital Whisperer is here to transform your everyday hospital experience, making it more efficient, organized, and seamless.

    With the Hospital Whisperer, managing tasks and patient information becomes a breeze. Our intelligent system ensures you stay organized and focused on delivering exceptional care to your patients. From treatment planning to monitoring, you'll have all the tools you need to provide the best possible medical care.
    
    Say goodbye to information silos! Our smart integration with hospital systems enables effortless data exchange, giving you quick access to essential patient records and fostering smooth collaboration with your healthcare team.
    
    At Hospital Whisperer, we are driven by a passion for healthcare excellence. Our goal is to empower you with cutting-edge technology that simplifies your daily routines, so you can spend more time doing what you love – caring for your patients.
    
    Your dedication to healthcare inspires us, and we're committed to supporting you on your journey. Trust in Hospital Whisperer to enhance your healthcare practice, optimize your workflow, and ultimately improve patient outcomes.
    
    Thank you for choosing Hospital Whisperer. Together, let's revolutionize the way healthcare is delivered and create a brighter future for all.
    
    Welcome aboard!          
  `,
  }, 
  "dashboard": {
    "title": "Dashboard", 
    "statistics": {
      "users": {
        "title" : "Users", 
        "subtitle": "Our user base has grown exponentially, comprising a diverse community of healthcare professionals, developers, and security enthusiasts. Together, we are dedicated to advancing knowledge and awareness in hospital software security."
      },
      "patients": {
        "title" : "Patients", 
        "subtitle": "We proudly serve a substantial number of patients, entrusted to us by medical institutions worldwide. Ensuring the utmost privacy and data protection is at the core of our mission."
      },
      "appointments": {
        "title" : "Appointments", 
        "subtitle": "The volume of appointments managed through our platform reflects the efficiency and reliability of our hospital software solutions. We prioritize the security of these scheduling processes to optimize patient care."
      },
      "notes": {
        "title" : "Notes", 
        "subtitle": "Our extensive collection of notes within the system is a testament to the comprehensive medical records maintained by healthcare providers. Security measures are rigorously implemented to safeguard this critical information."
      },
    }
  }, 
  "register": {
    "title": "Register",
    "fields": {
      "username": {
        "label": "Username", 
        "placeholder": "The Famous Doctor"
      },
      "email": {
        "label": "Email", 
        "placeholder": "jean.michel@hospital-whisperer.com"
      }, 
      "password": {
        "label": "Password", 
        "placeholder": "password"
      },
      "captcha": {
        "label": "Captcha", 
        "placeholder": "120"
      }
    },
    "button": "Register",
    "already-has-account": "Already has an account ?",
    "login": "Sign-in"
  }
};

export default en;
