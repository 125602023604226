import { enc, AES } from "crypto-js";

function stringToHex(input: string) {
  let hexString = '';
  for (let i = 0; i < input.length; i++) {
    const hexValue = input.charCodeAt(i).toString(16);
    hexString += hexValue.padStart(2, '0');
  }
  return hexString;
}

function passphraseToKey(passphrase: string) {
  return stringToHex(passphrase.toUpperCase().padEnd(32, "?")).slice(0, 32).toUpperCase();
}

export function encode(text: string, passphrase: string) {
  const key = enc.Hex.parse(passphraseToKey(passphrase));
  const iv = enc.Hex.parse(passphraseToKey(passphrase));

  // Derive the AES key from the passphrase using PBKDF2
  
  const encrypted = AES.encrypt(text, key, { iv: iv });

  return btoa(JSON.stringify({
    iv: iv.toString(),
    encryptedText: encrypted.toString(),
  }));
}

export function decode(encryptedData: any, passphrase: string) {
  try {
    const data = JSON.parse(atob(encryptedData));
    const iv = enc.Hex.parse(data.iv);

    // Derive the AES key from the passphrase using PBKDF2
    const key = enc.Hex.parse(passphraseToKey(passphrase));

    const decrypted = AES.decrypt(data.encryptedText, key, { iv: iv });
    return decrypted.toString(enc.Utf8);
  } catch {
    return { error: "Failed to decipher" };
  }
}
