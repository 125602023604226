import axios from "axios";
import { getAuthorization } from "../utils/auth";
import { alerts } from "../utils/alerts";

const getBaseUrl = () => {
  let url;
  switch(process.env.NODE_ENV) {
    case 'production':
      url = 'https://api.hospital-whisperer.com';
      break;
    case 'development':
    default:
      url = 'http://127.0.0.1:8010';
  }

  return url;
}


  const ax = axios.create({
    baseURL: getBaseUrl(),
  });

  ax.interceptors.request.use(function (request) {
    const auth = getAuthorization();
    if (auth) {
      request.headers.Authorization = auth;
    }
    return request;
  })


    // Add a response interceptor
    ax.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          (window as any).location = "/login";
          return { error: "Unauthorized" };
        } else if (error.response) {
          alerts.addAlert({
            title: "API ERROR OCCURED: " + error.response.status,
            summary: error.response.data,
            type: "error"
          });
        }
        return { error: error };
      }
    );
  

  export default ax 