import "./Sidebar.scss";

import React from "react";
import { NavLink } from "react-router-dom";
import { t } from "i18next";
import {
  CalendarDaysIcon,
  ChartPieIcon,
  FolderOpenIcon,
  LockClosedIcon,
} from "@heroicons/react/24/outline";
import LangPicker from "./lang-picker/LangPicker";

class Sidebar extends React.Component<{}, {}> {
  private menu = [
    {
      icon: <ChartPieIcon />,
      label: "sidebar.dashboard",
      path: "/dashboard",
    },
    {
      icon: <CalendarDaysIcon />,
      label: "sidebar.appointments",
      path: "/appointments",
    },
    {
      icon: <FolderOpenIcon />,
      label: "sidebar.patients",
      path: "/patients",
    },
    {
      separator: true,
    },
    {
      icon: <LockClosedIcon />,
      label: "sidebar.secret_notes",
      path: "/secretNotes",
    },
  ];

  render() {
    return (
      <div>
        <aside
          id="logo-sidebar"
          className="fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform -translate-x-full border-r border-gray-200 sm:translate-x-0 bg-gray-800 border-gray-700"
          aria-label="Sidebar"
        >
          <div className="h-full px-3 pb-4 overflow-y-auto flex flex-col">
            <ul className="space-y-2 font-medium flex-1">
              {this.menu.map((it, idx) => {
                if (it.separator) {
                  return (
                    <li
                      key={idx}
                      className="font-medium border-t border-gray-700"
                      style={{ marginTop: 10, marginBottom: 10 }}
                    ></li>
                  );
                }
                return (
                  <li key={idx}>
                    <NavLink
                      to={it.path!}
                      className={(props) =>
                        props.isActive
                          ? "flex items-center p-2 rounded-lg  text-gray-500 transition duration-75 bg-gray-900 text-white"
                          : "flex items-center p-2 rounded-lg  text-gray-500 transition duration-75 hover:bg-gray-100 hover:bg-gray-700 hover:text-white"
                      }
                    >
                      <div className="w-5 h-5 text-inherit transition duration-75">
                        {it.icon}
                      </div>
                      <span className="ml-3 text-inherit transition duration-75">
                        {t(it.label!)}
                      </span>
                    </NavLink>
                  </li>
                );
              })}
            </ul>
            <div className="text-gray-600 text-sm text-center flex-end">
              <LangPicker></LangPicker>
              <div>
                {t("technical.commit_hash")}
              </div>
            </div>
          </div>
        </aside>
      </div>
    );
  }
}

export default Sidebar;
