import './Logout.scss';
import React from 'react';
import { performLogout } from '../../../utils/auth';
import { Navigate } from 'react-router-dom';

class Logout extends React.Component<{}, {}> {
  
  render() {
    performLogout()

    return (
      <Navigate to="/login"/>
    );
  }
}

export default Logout;


