import "./Home.scss";

import React from "react";
import { getUser } from "../../../utils/auth";
import { t } from "i18next";

class Home extends React.Component<{}, { userData: any }> {
  constructor(props: any) {
    super(props);
    this.state = { userData: null };
    this.loadUserData();
  }

  async loadUserData() {
    this.setState({ userData: await getUser() });
  }

  render() {
    if (!this.state.userData) {
      return [];
    }
    return (
      <div className="">
        <div className="text-center p-20">
        <h1 className="text-4xl my-10 font-bold">
          {t("home.title")}
        </h1>
        <p className="text-gray-500 text-md px-10" style={{whiteSpace: "pre-line"}}>
        {t("home.text")}
        </p>
        </div>
      </div>
    );
  }
}

export default Home;
