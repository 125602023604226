import "./Patients.scss";

import React from "react";
import { getUser } from "../../../utils/auth";
import { t } from "i18next";
import ax from "../../../http/axios";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { NavLink } from "react-router-dom";
import { withSearchParams } from "../../../helpers";



class Patients extends React.Component<
  {searchParams: any, setSearchParams: any},
  { userData: any; patients: any; }
> {
  constructor(props: any) {
    super(props);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.state = { userData: null, patients: null };
    this.loadData();
  }

  async loadData() {
    const user = await getUser();
    let patients = []
    if (this.props.searchParams.get("search")) {
      patients = (await ax.get("/api/patients",{params: {search: this.props.searchParams.get("search")}})).data;
    } else {
      patients = (await ax.get("/api/patients")).data;
    }
    if (user.role !== "admin") { 
      patients = patients.filter((it: any) => it.vip == false);
    }
    this.setState({ userData: user, patients: patients });
  }

  async onSubmitHandler(e: any) {
    e.preventDefault();
    const form = Object.fromEntries(
      new FormData(e.target).entries()
    ) as any;
    this.props.setSearchParams(new URLSearchParams(form))
    await this.loadData()
  }

  

  render() {
    if (!this.state.userData) {
      return [];
    }
    return (
      <div>
        <form className="my-16 mx-16" onSubmit={this.onSubmitHandler}>
          <label className="text-sm font-medium sr-only text-white">
            Search
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <MagnifyingGlassIcon className="w-4 h-4 text-gray-400"/>
            </div>
            <input
              type="search"
              name="search"
              className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
              placeholder={t('patients.search.placeholder')}
              required
            />
            <button
              type="submit"
              className="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 bg-blue-600 hover:bg-blue-700 focus:ring-blue-800"
            >
              {t('patients.search.button')}
            </button>
          </div>
        </form>

        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-400">
            <caption className="p-5 text-lg font-semibold text-left text-white bg-gray-800">
              <span
                dangerouslySetInnerHTML={{
                  __html: t("patients.list-title", {
                    search: this.props.searchParams.get("search"),
                    interpolation: { escapeValue: false },
                  }),
                }}
              ></span>
              <p className="mt-1 text-sm font-normal text-gray-400">
                {t("patients.list-summary")}
              </p>
            </caption>
            <thead className="text-xs  uppercase bg-gray-700 text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  {t("patients.list.headers.firstname")}
                </th>
                <th scope="col" className="px-6 py-3">
                  {t("patients.list.headers.lastname")}
                </th>

  

                <th scope="col" className="px-6 py-3">
                  {t("patients.list.headers.identity_number")}
                </th>

                <th scope="col" className="px-6 py-3">
                  {t("patients.list.headers.date_of_birth")}
                </th>

                <th scope="col" className="px-6 py-3">
                  {t("patients.list.headers.address")}
                </th>
                <th scope="col" className="px-6 py-3">
                  {t("patients.list.headers.phone_number")}
                </th>

                <th scope="col" className="px-6 py-3">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.patients.map((patient: any) => {
                return (
                  <tr
                    key={patient.identity_number}
                    className="border-b bg-gray-800 border-gray-700"
                  >
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap text-white"
                    >
                      {patient.firstname}
                    </th>
                    <td className="px-6 py-4">{patient.lastname}</td>
                    <td className="px-6 py-4">{patient.identity_number}</td>
                    <td className="px-6 py-4">{patient.date_of_birth}</td>
                    <td className="px-6 py-4">{patient.address}</td>
                    <td className="px-6 py-4">{patient.phone_number}</td>
                    <td className="px-6 py-4 text-right">
                      <NavLink
                        to={"/patient/" + patient.identity_number}
                        className="font-medium text-blue-600 text-blue-500 hover:underline"
                      >
                        {t('patients.list.view')}
                      </NavLink>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default withSearchParams(Patients);
