import React, { ReactNode } from "react";
import { getUser } from "../../../../../utils/auth";

class DefaultRightMenuButton extends React.Component<
  {
    label: string;
    icon: ReactNode;
    children: ReactNode[] | ReactNode;
  },
  {
    userData: any;
    isMenuOpened: boolean;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = { isMenuOpened: false, userData: null };
    this.loadUserData();
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu() {
    this.setState((prevState) => ({
      isMenuOpened: !prevState.isMenuOpened,
      userData: prevState.userData,
    }));
  }

  async loadUserData() {
    const user = await getUser()
    this.setState((prevState) => ({
      isMenuOpened: prevState.isMenuOpened,
      userData: user,
    }));
  }

  render() {
    if(this.state.userData == null){
        return []
    }
    return (
      <div className="relative ml-3">
        <div className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium text-gray-900 rounded-full group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800">
          <button
            type="button"
            onClick={this.toggleMenu}
            className="rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
          >
            <span className="sr-only">{this.props.label}</span>
            <div className="h-6 w-6">{this.props.icon}</div>
          </button>
        </div>
        {this.props.children && (
          <div
            className={
              (this.state.isMenuOpened ? "" : "hidden") +
              " absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none bg-gray-700"
            }
            aria-orientation="vertical"
            aria-labelledby="user-menu-button"
            tabIndex={-1}
          >
            <div className="px-4 py-3 border-b border-gray-600" role="none">
              <p className="text-sm font-bold text-white" role="none">
                {this.state.userData.username}
              </p>
              <p
                className="text-sm font-medium truncate text-gray-300"
                role="none"
              >
                {this.state.userData.email}
              </p>
            </div>
            {this.props.children}
          </div>
        )}
      </div>
    );
  }
}

export default DefaultRightMenuButton;
