
import React from 'react';
import DefaultRightMenuButton from './DefaultRightMenuButton/DefaultRightMenuButton';
import { SubMenu } from '../Menu';

import {
  NavLink
} from "react-router-dom";
import { t } from 'i18next';

class DefaultRightMenu extends React.Component {


  render() {
    return (
      <div className="hidden md:block">
        <div className="ml-4 flex items-center md:ml-6">

          {
            SubMenu.map((it) => {
              return (
                <DefaultRightMenuButton
                  key={t(it.label)}
                  label={t(it.label)}
                  icon={it.icon}>
                  {
                    it.links && it.links.map((jt) => {
                      return (
                        <NavLink 
                          key={jt.path}
                          to={jt.path}
                          className={
                            props => props.isActive ?
                            "block px-4 py-2 text-sm hover:bg-gray-100 text-gray-300 hover:bg-gray-600 hover:text-white" : 
                              "block px-4 py-2 text-sm hover:bg-gray-100 text-gray-300 hover:bg-gray-600 hover:text-white"
                          }>
                          {t(jt.label)}
                        </NavLink>
                      )
                    })
                  }
                </DefaultRightMenuButton>
              )
            })
          }




        </div>
      </div>

    );
  }
}

export default DefaultRightMenu;


