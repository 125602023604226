import { useTranslation } from "react-i18next";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

export function withLocation(Component: any) {
  return (props: any) => <Component {...props} location={useLocation()} />;
}

export function withNavigate(Component: any) {
  return (props: any) => <Component {...props} navigate={useNavigate()} />;
}

export function withParams(Component: any) {
  return (props: any) => <Component {...props} params={useParams()} />;
}

export function withTranslation(Component: any) {
  return (props: any) => <Component {...props} translation={useTranslation()} />;
}



export function withSearchParams(Component: any) {
  return (props: any) => {
    const [searchParams, setSearchParams] = useSearchParams();
    return (
      <Component
        {...props}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
      />
    );
  };
}
