import "./LangPicker.scss";

import React from "react";
import { t } from "i18next";
import i18n from 'i18next';

import { withNavigate } from "../../../../helpers";
import { langs } from "../../../../langs";

class LangPicker extends React.Component<{
}, {
    visible: boolean
}> {

    constructor(props: any) {
        super(props);
        this.state = {visible: false};
    }
 


  private toggleMenu(){
    this.setState({visible: !this.state.visible})
  }


  private setLang(lang: string)  {
    i18n.changeLanguage(lang);
    (window.location as any) = (window.location as any)
  }


  render() {
    return (
      <div className="py-10"> 
       <div className={(this.state.visible ? 'block': 'hidden') + " z-50 my-4 text-base text-left list-none divide-y divide-gray-100 rounded-lg shadow bg-gray-700"}>
        <ul className="py-2 font-medium" role="none">
            {
                langs.map((lang) => {
                    return (
                        <li key={lang.lang}>
                        <span  
                            className="block cursor-pointer px-4 py-2 text-sm  text-gray-400 hover:bg-gray-600 hover:text-white" role="menuitem"
                            onClick={() => this.setLang(lang.lang)}>
                          <div className="inline-flex items-center">
                            {t(lang.label)}
                          </div>
                        </span>
                      </li>
                    )
                })
            }
 
        </ul>
      </div>
      <button 
        type="button" 
        className="inline-flex items-center font-medium justify-center px-4 py-2 text-sm text-gray-400 rounded-lg cursor-pointer hover:bg-gray-100 hover:bg-gray-700 hover:text-white"
        onClick={() => this.toggleMenu()}>
        {t("lang.current")} 
      </button>
 
      </div>
    );
  }
}

export default withNavigate(LangPicker);
