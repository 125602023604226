import ax from "../http/axios";

export async function getUser() {
    return (await ax.get("/api/user")).data
}

export function getAuthorization() {
    const cookie = document.cookie;
    if(cookie && cookie.match(/token=/)){
        return ((cookie || "").match(/token=(\S+)/) as any)[1]
    }
    return null
}

export async function performLogin(email: string, password: string){
    const res = await ax.post("/api/login", {email, password})
    if(res.data && (res.data as any).token) {
        const token = (res.data as any).token
        document.cookie = "token=" + token + "; max-age=36400"
        return true
    }
    return false
}

export async function performRegister(username: string, email: string, password: string, question: string, answer: string){
    const res = await ax.post("/api/register", {username, email, password, question, answer})
    if(res.status === 200) {
        return await performLogin(email, password)
    }
    return false
}

export async function performLogout() {
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC;" 
}