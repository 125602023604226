import { t } from "i18next";
import { withParams } from "../../../helpers";
import ax from "../../../http/axios";
import "./Patient.scss";

import React from "react";

class Patient extends React.Component<
  {},
  {
    patient: any;
    appointments: any[];
    notes: any[];
  }
> {
  constructor(props: any) {
    super(props);
    this.state = { patient: null, appointments: [], notes: [] };
    this.loadData();
  }

  async loadData() {
    const patientId = (this.props as any).params.id;
    const patient = (await ax.get("/api/patient/" + patientId + "")).data;
    const appointments = (
      await ax.get("/api/patient/" + patientId + "/appointments")
    ).data;
    const notes = (await ax.get("/api/patient/" + patientId + "/notes")).data;
    this.setState({ patient, appointments, notes });
  }

  calculateAge(birthdate: string) {
    // Step 1: Get the current date
    const currentDate = new Date();

    // Step 2: Parse the birthdate
    const [year, month, day] = birthdate.split("-");
    const birthDateObj = new Date(year as any, (month as any) - 1, day as any); // Note: Months are 0-based in Date constructor

    // Step 3: Calculate the difference between the current date and the birthdate
    let age = currentDate.getFullYear() - birthDateObj.getFullYear();

    // Check if the birthday has occurred this year or not
    const hasBirthdayOccurred =
      currentDate.getMonth() > birthDateObj.getMonth() ||
      (currentDate.getMonth() === birthDateObj.getMonth() &&
        currentDate.getDate() >= birthDateObj.getDate());

    if (!hasBirthdayOccurred) {
      // If the birthday has not occurred, subtract 1 from the age
      age--;
    }

    // Step 4: Return the age
    return age;
  }

  render() {
    if (!this.state.patient) {
      return [];
    }

    const p = this.state.patient;
    const age = this.calculateAge(p.date_of_birth);
    const notes = this.state.notes;
    const pastAppointments = this.state.appointments.filter(
      (it) => new Date(it.date_and_time) < new Date()
    );
    const nextAppointments = this.state.appointments.filter(
      (it) => new Date(it.date_and_time) > new Date()
    );
    return (
      <div className="patient">
        <div className="grid grid-cols-2 gap-4 mb-4">
          <div className=" p-5 rounded bg-gray-50 bg-gray-800 relative">
            <div className="name text-xl font-bold">
              <span>{p.firstname + " " + p.lastname}</span>
              {p.vip ? (
                <span
                  className="bg-yellow-100 ml-5 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded relative"
                  style={{ top: "-2.5px" }}
                >
                  {t("patient.vip")}
                </span>
              ) : (
                []
              )}
            </div>
            <div className="identity_number absolute right-5 top-5 bold text-xl font-bold text-gray-600">
              {p.identity_number}
            </div>
            <div className="age text-gray-400">
              {t("patient.age", { age, dob: p.date_of_birth })}
            </div>
            <div className="gender text-sm text-gray-500">{p.gender}</div>
          </div>
          <div className=" p-5 rounded bg-gray-50 bg-gray-800">
            <div className="phone_number text-xl font-bold text-gray-500">
              {p.phone_number}
            </div>
            <div className="address text-gray-600">{p.address}</div>
          </div>
        </div>

        <h1 className="text-xl text-center m-8 font-bold">
          {" "}
          {t("patient.past-appointments")}
        </h1>

        <div className=" p-5 rounded bg-gray-50 bg-gray-800 relative">
          {pastAppointments.length == 0 ? (
            <span className="text-gray-400">
              {t("patient.no-past-appointments")}
            </span>
          ) : (
            <div>
              {pastAppointments.map((ap) => {
                return (
                  <div key={ap}>
                    <span className="font-bold">
                      {ap.date_and_time.split("T")[0]}{" "}
                    </span>
                    -<span className="text-gray-400"> {ap.reason} </span>
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <h1 className="text-xl text-center m-8 font-bold">
          {t("patient.next-appointments")}
        </h1>

        <div className=" p-5 rounded bg-gray-50 bg-gray-800 relative">
          {nextAppointments.length == 0 ? (
            <span className="text-gray-400">
              {t("patient.no-next-appointments")}
            </span>
          ) : (
            <div>
              {nextAppointments.map((ap) => {
                return (
                  <div key={ap}>
                    <span className="font-bold">
                      {ap.date_and_time.split("T")[0]}{" "}
                    </span>
                    -<span className="text-gray-400"> {ap.reason} </span>
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <h1 className="text-xl text-center m-8 font-bold">
          {" "}
          {t("patient.notes")}
        </h1>
        {notes.length == 0 ? (
          <div className=" p-5 rounded bg-gray-50 bg-gray-800 relative">
            <span className="text-gray-400">{t("patient.no-notes")}</span>
          </div>
        ) : (
          <div>
            {notes.map((n: any) => {
              return (
                <div key={n}>
                  <blockquote className="p-4 my-4 border-l-4  border-gray-700 bg-gray-800">
                    <p className="text-sm italic font-medium leading-relaxed  text-gray-400" style={{whiteSpace: 'pre-line'}}>
                      {n.note}
                    </p>
                  </blockquote>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

export default withParams(Patient);
